<template>
  <div class="admin-tollfree-list">
    <div v-if="!isLoading && uploads" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>Tollfree Verification</h5>
            <div class="row mb-md-2 mt-4">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="datatable.queries.per_page" class="ml-1 mr-1" size="sm"
                      :options="[10, 15, 25, 50, 100]" @change="loadData"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="datatable.queries.search" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                  <button class="ml-1 btn btn-primary btn-sm" @click="onQueryChange('search')">
                    <i class="uil uil-search-alt"></i>
                  </button>
                  <button class="ml-1 btn btn-primary btn-sm" @click="refresh">
                    <i class="uil uil-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table :items="uploads.data" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc" :per-page="datatable.queries.per_page">
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
                <template v-slot:cell(number)="data">
                  {{ data.item.number | nationalNumber }}
                </template>
                <template v-slot:cell(status)="data">
                  <span v-if="data.item.status">
                    {{ data.item.status.toLowerCase() | capitalize }}
                  </span>
                </template>
                <template v-slot:cell(submitted_at)="data">
                  <span v-if="data.item.submitted_at">
                    {{ data.item.submitted_at | moment('MM-DD-YYYY hh:mm A') }}
                  </span>
                </template>
                <template v-slot:cell(actions)="data">
                  <a v-b-tooltip.hover class="btn btn-sm btn-primary" title="View Detail"
                    @click="onClickDetail(data.item)">
                    <i class="uil uil-eye"></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row mt-4">
              <div class="col">
                <div class="float-left mt-2">
                  Total {{ uploads.meta.total }} requests
                </div>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="datatable.queries.page" :total-rows="uploads.meta.total"
                      :per-page="datatable.queries.per_page" @change="onPageChanged"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'agency_name', label: 'Agency Name' },
          { key: 'business_name', label: 'Business Name' },
          { key: 'number', label: 'Number' },
          { key: 'status', label: 'Status' },
          { key: 'submitted_at', label: 'SubmitAt' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
        },
      },
      uploads: null,
      isLoading: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.loadData()
  },

  methods: {
    onPageChanged(page) {
      this.datatable.queries.page = page
      this.loadData()
    },

    onQueryChange(type = undefined) {
      this.$nextTick(() => {
        if (type === 'search') {
          this.datatable.queries.page = 1;
        }
        this.loadData()
      });
    },

    onClickDetail(item) {
      this.$router.push({ name: 'admin.settings.tollfree.edit', params: { id: item.id } })
    },

    refresh() {
      this.datatable.queries.search = null
      this.loadData();
    },

    loadData() {
      this.isLoading = true

      this.$store.dispatch('admin/getTollfreeVerificationList', this.datatable.queries)
        .then(uploads => {
          this.uploads = uploads
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
  